import React from "react"
import { Footer } from "../components/footer/Footer"
import { Header } from "../components/header/Header"
import BannMarque from "../components/marque/BanniereMarque"
import MarqueContenu from "../components/marque/marqueContent"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const marque = ({ data, pageContext }) => {
  console.log(pageContext)
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marque - {pageContext.name_marque.replaceAll("_", " ")}</title>
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Marque - ${pageContext.name_marque}`}
        />

        <meta
          name="description"
          content={`Contient les produits de la marque ${pageContext.name_marque}`}
        />

        <meta
          property="og:description"
          content={`Contient les produits de la marque ${pageContext.name_marque}`}
        />
        <meta
          property="og:url"
          content={`https://brand.setem.fr/${pageContext.name_marque}/`}
        />
        <meta property="og:site_name" content="SETEM" />
      </Helmet>
      <Header />
      <BannMarque name={pageContext.name_marque} />
      <MarqueContenu
        name={pageContext.name_marque}
        data={data}
        currentPage={pageContext.currentPage}
        numPages={pageContext.numPages}
        numberOfProducts={pageContext.numberOfProducts}
        brandWithUsine={pageContext.brandWithUsine}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ($name_marque: String,$skip: Int!, $limit: Int!, $brandReferences: [String]!) {
      brandS3(hit: { name: { eq: $name_marque } }) {
        hit {
          name
          description
        }
      }
      allProductS3(
        sort: { fields: hit___name, order: ASC }
        filter: { hit: { brand: { eq: $name_marque }, name: { ne: "00_LOGO-ETIQUETTES" }, type : {eq : "directory"} } }
        limit: $limit
        skip: $skip
      ) {
        edges {
          node {
            hit {
              name
              type
              brand
              children {
                name
                type
              }
            }
          }
        }
      }
      
      allSetemProduct(filter: {hit : {_source : {reference : {in : $brandReferences}}}}){
        edges{
          node{
             key,
             hit{
                _source{
                    usine
                    reference
                    image_url
                    code
                    brand
                }
             }
          }
        }
      }    
      
  }
`


export default marque
